import React, { useEffect, useContext, useRef } from "react"
import *  as scss from './ProjectDetails.module.scss'

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "components/globalContextProvider/GlobalContextProvider"

import ProjectDetailsGl from "componentsWebgl/projectDetails/ProjectDetails"

const componentName = 'ProjectDetails'

const ProjectDetails = ( {data, link } ) => {

  // ------------------------------------------------ CONTEXT
  const globalState = useContext(GlobalStateContext)

  // ------------------------------------------------ DATA
  const slice = globalState.projectDetails

  // ------------------------------------------------ REFS
  const refContainer = useRef<HTMLDivElement>()
  const refInnerContainer = useRef<HTMLDivElement>()
  const refFields = useRef([])
  refFields.current = []
  const refContents = useRef([])
  refContents.current = []
  
  const addToRefsFields = (item: HTMLElement) => {
    if (item && !refFields.current.includes(item)) {
      refFields.current.push(item)
    }
  }

  const addToRefsContents = (item: HTMLElement) => {
    if (item && !refContents.current.includes(item)) {
      refContents.current.push(item)
    }
  }

  // ------------------------------------------------ FIND & HANDLE COMPONENTS WEBGL
  const handleWebgl =  () => {
    if (slice)   {
      const object = new ProjectDetailsGl({
        container: refContainer.current,
        fields: refFields.current,
        contents: refContents.current
      })
      slice.webgl = object
    }
  }

  const handleMouseEnter = (event) => {
    if (slice && slice.webgl) {
      slice.webgl.mouseEnter(event.currentTarget)
    }
  }

  
  const handleMouseLeave = (event) => {
    if (slice && slice.webgl) {
      slice.webgl.mouseLeave(event.currentTarget)
    }
  }

  // ------------------------------------------------ USEEFFECT WEBGL
  useEffect(() => {
    globalState.webgl && handleWebgl()
    return () => {
      globalState.webgl && slice.webgl.destroy()
    }
  }, [globalState.webgl])
  
  useEffect(() => {
    if (slice && slice.webgl) {
      slice.webgl.setTheme(globalState.theme)
    }
  }, [globalState.theme])
  
  return (
    <div className={scss.root} ref={refContainer}>
      <div ref={refInnerContainer} className={scss.container}>
        <div className={scss.innerContainer}>
          {
            data.map(({ field_title, content, link}, index: number) => (
              <div
                key={`projectDetails--${index}`}
                draggable={false}>
                  <span className={scss.field} ref={addToRefsFields}>{field_title}</span>
                  {
                    link.url ? <a href={link.url} target={link.target} className={scss.link} ref={addToRefsContents} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>{content}</a> : <span className={scss.content} ref={addToRefsContents}>{content}</span>
                  }
              </div>
              )
            )
          }
          <div className={scss.visite}><a href={link.url} target="_blank" ref={addToRefsContents} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>Visite Site</a></div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails