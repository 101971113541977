import React, { useEffect, useContext} from "react"
import { graphql, PageProps } from "gatsby"

import * as scss from "./Project.module.scss"
import { SEO } from 'components/seo/Seo'
import { ISinglePageTemplateData } from "data/dataStruct"
import ProjectDetails from "components/projectDetails/ProjectDetails"

const Project: React.FC<PageProps<ISinglePageTemplateData>> = ({ data }) => {
  const { project } = data
  const componentName = project.uid

  return (
    <div className={scss.root}>
      <SEO title={project.data.meta_title} description={project.data.meta_description} />
      <ProjectDetails data={project.data.details} link={project.data.site_link}/>
    </div>
  )
}

export default Project

// ------------------------------ QUERY
export const query = graphql`
query project($uid: String!) {
  project : prismicProjectTemplate(uid: {eq: $uid}) {
    ...projectTemplateData
  }
}
`