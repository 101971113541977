
import {
  ShaderMaterial,
  Color,
  Clock
} from "three"

import { Text } from "troika-three-text"
import scroll from "lib/scroll"

import canvas from "lib/canvas"
import fragment from './_fragment.glsl'
import vertex from './_vertex.glsl'
import { TScroll, IClientListItem } from 'data/'
import GSAP from 'gsap'

import mirthaItalic from "fonts/MirthaDisplayItalic.woff"
import mirthaBold from "fonts/MirthaDisplayBold.woff"
import MirthaDisplayBoldItalic from "fonts/MirthaDisplayBoldItalic.woff"
import mirtha from "fonts/MirthaDisplayRegular.woff"

interface ProjectDetailsItem extends IClientListItem {}

class ProjectDetailsItem  { 
  constructor( { domElement } )  {
    this.clock = new Clock()
    this.domElement = domElement
    this.font = {
      MirthaDisplayItalic: mirthaItalic,
      MirthaDisplayBold: mirthaBold,
      MirthaDisplayBoldItalic: MirthaDisplayBoldItalic
    }

    this.glElements = {
      geometry: new Text(),
      material: new ShaderMaterial(),
    }

    this.options = {
      isBefore: null,
      isAfter: null,
      isHover:  false
    }

    this.bind()

    this.createBounds()
    this.setPosition()
    this.createGeometry()
    this.createMaterial()
    this.addToScene()
  }


  bind() {
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.destroy = this.destroy.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  // ------------------------------------------------ SETUP

  createBounds() {
    const bounds = this.domElement.getBoundingClientRect()

    this.bounds = {
      top: bounds.top + scroll.ASScroll.currentPos,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      canvasWidth: canvas.container.offsetWidth,
      canvasHeight: canvas.container.offsetHeight
    }

  }

  createGeometry() {
    this.glElements.geometry.text = `${this.domElement.innerText}`
    this.glElements.geometry.font = this.font[getComputedStyle(this.domElement).fontFamily]

    this.glElements.geometry.textAlign = getComputedStyle(this.domElement).textAlign
    
    this.glElements.geometry.anchorX = 'center'
    this.glElements.geometry.anchorY = 'middle'

    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
    this.glElements.geometry.lineHeight = 1.15
    this.glElements.geometry.sdfGlyphSize = 64
    // this.glElements.geometry.outlineWidth = 0.1
    // this.glElements.geometry.fillOpacity = 0.1
    if (this.domElement.nodeName === 'A') this.glElements.geometry.glyphGeometryDetail = 15
  }

  createMaterial() {
    this.glElements.material.uniforms ={
      u_viewportSizes: { value: [window.innerWidth, window.innerHeight] },
      u_color: { value : new Color(getComputedStyle(this.domElement).color)},
      u_alpha: { value : 0.0 },
      u_strength: { value: 0.0 },
      u_textLength: { value: 0.0},
      u_time: { value: 0.0}
    }
    this.glElements.material.vertexShader = vertex
    this.glElements.material.fragmentShader = fragment
    this.glElements.geometry.material = this.glElements.material
    this.glElements.geometry.layers.set(1)
  }

  addToScene() {
    canvas.scene.add(this.glElements.geometry)
    this.glElements.geometry.sync()
    this.glElements.geometry.addEventListener('synccomplete', () => {
      this.glElements.geometry.sdfGlyphSize = 512
      this.onResize()
      this.reveal()
    })
  }

  reveal() {
    const timeline = GSAP.timeline()
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value: 1.0
    })
  }
  
  setColor(theme : string) {
    const initialColor = theme === "outerSpace" ? new Color('#283f38') : new Color('#f5cdd5')
    const newColor = theme === "outerSpace" ? new Color('#f5cdd5') : new Color('#283f38')
    const timeline = GSAP.timeline()

    timeline.to(initialColor, {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      onUpdate: () => {
        this.glElements.geometry.material.uniforms.u_color.value.r = initialColor.r
        this.glElements.geometry.material.uniforms.u_color.value.g = initialColor.g
        this.glElements.geometry.material.uniforms.u_color.value.b = initialColor.b
      }
    })
    return timeline
  }
  // ------------------------------------------------ EVENTS
  onResize() {
    this.createBounds()
    this.glElements.geometry.fontSize = parseFloat(getComputedStyle(this.domElement).fontSize)
    this.setPosition()
  }

  onMouseEnter() {
    const timeline = GSAP.timeline() 
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value: 0.5
    },0)
    timeline.to(this.glElements.geometry.scale, {
      x : 1.1,
      y : 1.1,
      z : 1.1,
    },0)
  }

  onMouseLeave() {
    const timeline = GSAP.timeline() 
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value: 1
    },0)
    timeline.to(this.glElements.geometry.scale, {
      x : 1,
      y : 1,
      z : 1,
    },0)
  }
  // ------------------------------------------------ POSITIONS
  setPosition() {
    this.glElements.geometry.position.x = (this.bounds.left - this.bounds.canvasWidth / 2) + this.bounds.width / 2
    this.glElements.geometry.position.y = scroll.ASScroll.currentPos -this.bounds.top + this.bounds.canvasHeight / 2 - this.bounds.height / 2
  }

  update() {
    if (!this.isHover) return
    const elapsedTime = this.clock.getElapsedTime()
    // this.glElements.geometry.material.uniforms.u_strength.value += Math.sin(elapsedTime * 0.3)
  }

  // ------------------------------------------------ DESTROY
  destroy() {
    const timeline = GSAP.timeline({
      onComplete: () => canvas.scene.remove( this.glElements.geometry )
    })
    timeline.to(this.glElements.geometry.material.uniforms.u_alpha, {
      value: 0.0
    })
  }
}

export default ProjectDetailsItem